import React from "react"
import { Container, Row, Col, Button } from "react-bootstrap"
import { Icon } from "react-icons-kit"
import { facebookOfficial } from "react-icons-kit/fa/facebookOfficial"
import { shoppingCart } from "react-icons-kit/fa/shoppingCart"
import { instagram } from "react-icons-kit/fa/instagram"

function Footer() {
  return (
    <>
      <div className="footer-container">
        <div className="height-40" />
        <Container style={{ padding: "40px" }}>
          <Row>
            <Col className="text-center" style={{ fontWeight: 600 }}>
              <p>Multilógica-shop Tutoriais</p>
            </Col>
          </Row>
          <Row>
            <Col xs={0} sm={5} />
            <Col sm={2} style={{ padding: "12px 0" }}>
              <Container>
                <div className="height-20" />
                <Row className="text-center">
                  <Col xs={4}>
                    <Button
                      className="image-button"
                      style={{ margin: 0 }}
                      href="https://multilogica-shop.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="instagram"
                    >
                      <Icon size={"30px"} icon={shoppingCart} />
                    </Button>
                  </Col>
                  <Col xs={4}>
                    <Button
                      className="image-button"
                      style={{ margin: 0 }}
                      href="https://www.instagram.com/multilogicashop/"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="instagram"
                    >
                      <Icon size={"30px"} icon={instagram} />
                    </Button>
                  </Col>
                  <Col xs={4}>
                    <Button
                      className="image-button"
                      style={{ margin: 0 }}
                      href="https://www.facebook.com/MultilogicaShop"
                      target="_blank"
                      rel="noopener noreferrer"
                      aria-label="facebook"
                    >
                      <Icon size={"30px"} icon={facebookOfficial} />
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default Footer
